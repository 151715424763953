body {
    padding-bottom: 50px;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a {
    text-decoration: none;
    color: black;
}

.section {
    width: 80%;
    margin-left: 10%;
}

#titleTage {
    background-color: tomato;
    border-radius: 15px;
    padding: 5px 12px;
    color: white;
}


/* ------------------covid-------------------- */

#covid {
    display: none;
    min-height: 5vh;
    background-color: tomato;
    align-items: center;
    max-width: 100vw;
    padding: 10px;
}

#covidImg {
    width: 34vw;
    text-align: right;
    padding-right: 1vw;
    ;
}

#covid label {
    font-weight: bold;
}

#covid p {
    width: 55vw;
}

#covidDismiss {
    text-align: right;
}

#covid i:hover {
    color: black !important;
}


/* ------------------header------------------- */

.split {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    color: black;
    padding: 10px 15px;
}

header label {
    padding: 3px 14px;
    color: white;
    background-color: black;
    font-size: 40px;
    font-weight: bold;
    box-shadow: 8px 0 0 tomato;
    text-shadow: 5px 0 0 tomato;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
}

header label:hover {
    box-shadow: 0 0 0 tomato;
    text-shadow: 0 0 0 tomato;
}

header label i:hover {
    color: white !important;
}

header a {
    text-align: center;
}

header a {
    font-weight: bold;
    font-size: 21px;
}

header a:hover {
    cursor: pointer;
    color: tomato;
    font-size: bold;
}

.align {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* ------------------intro---------------- */

#intro {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 93vh;
}

#slide1 {
    margin-left: 5%;
}

#slide1 h1 {
    font-size: 55px;
    margin-top: 36vh;
}

#slide1 select,
#slide1 label,
#slide1 label,
#goto_city {
    border: none;
    font-size: 35px;
    font-weight: bold;
}

#slide1 label {
    padding-right: 10px;
    font-weight: normal;
}

#slide1 option,
#goto_city option {
    background-color: white;
    color: black;
}

#socials {
    display: flex;
    padding-top: 20px;
}

#socials i {
    margin-right: 15px;
}

#intro i:hover {
    color: tomato;
    cursor: pointer;
}


/* -------------------about---------------- */

#about {
    padding-top: 170px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 60% 40%;
}

#about img {
    width: 80%;
    margin-left: 20%;
}

#about h1 {
    font-size: 30px;
}

#about_services {
    display: flex;
    flex-wrap: wrap;
    margin-top: 17px;
    width: 70%;
}

#about_services label {
    border: 1px solid black;
    padding: 4px 8px;
    border-radius: 8px;
    margin-right: 10px;
    margin-top: 10px;
    transition: all 0.2s ease-in-out;
}

#about_services label:hover {
    background-color: tomato;
    color: white;
    border: 1px solid tomato;
    cursor: pointer;
}


/* ---------------------location------------- */

#location {
    display: grid;
    grid-template-columns: 60% 40%;
}

#location p {
    width: 80%;
}

#location img {
    width: 80%;
    margin-left: 20%;
    box-shadow: 5px 5px 0 tomato;
}

#location label {
    font-size: 35px;
}

#location ul {
    margin-left: 15px;
}

#location button {
    padding: 4px 10px;
    border-radius: 13px;
    font-size: 23px;
    border: 1px solid black;
    background-color: white;
    transition: all 0.2s ease-in-out;
}

#location button:hover {
    cursor: pointer;
    background-color: tomato;
    border: 1px solid tomato;
    color: white;
}

#reach li {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* -----------------------covid 2----------------- */

#covid2 {
    display: grid;
    grid-template-columns: 40% 60%;
    margin-top: 100px;
}

#covid2 img {
    width: 80%;
}

#covid2 p {
    width: 80%;
}

#covidInfo i:not(#titleTage) {
    width: 40px;
}


/* -------------------form-------------- */

#formOver {
    display: none;
    padding: 50px;
    background-color: rgb(236, 236, 236);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 25px rgb(223, 223, 223);
}

#formOver p {
    font-weight: bold;
    width: 70%;
    font-size: 22px;
}

#form {
    padding-top: 100px;
    display: grid;
    grid-template-columns: 60% 40%;
}

#form #formFeild {
    background-color: rgb(223, 223, 223);
    overflow: hidden;
    border-radius: 15px;
    box-shadow: -0 0 30px gray;
    max-width: 450px;
}

#form_top {
    padding: 15px 15px 35px 15px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 90%;
    margin-left: 5%;
}

#form p {
    opacity: 0.7;
}

#form input[type="text"],
#form input[type="email"],
#form input[type="date"],
#form select,
textarea {
    padding: 10px;
    border: none;
    width: 80%;
    margin-top: 7px;
    border-radius: 13px;
    margin-bottom: 20px;
}

#form h1 {
    margin-left: 7%;
    margin-top: 5%;
}

textarea {
    resize: none;
    width: 80%;
}

#formSubmitBTN {
    background-color: tomato;
    padding: 11px;
    border: none;
    width: 100% !important;
    text-orientation: sideways;
    font-weight: bold;
    transition: all 0.4s ease-in-out;
    color: white;
}

#formSubmitBTN:hover {
    cursor: pointer;
    background-color: rgb(228, 140, 124);
}

#qoutes h3 {
    padding-top: 10px;
    font-size: 35px;
    font-weight: bold;
    width: 75%;
}

#qoutes p {
    display: inline-block;
    text-align: left;
    margin: 0;
}

#cont {
    display: grid;
    grid-template-columns: 50px auto;
    gap: 10px;
}

table td {
    padding: 5px 15px 0 0;
}


/* -------------------review--------- */

#review {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    padding: 10px;
    text-align: center;
    border-radius: 15px;
}

#review div {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
}

#review div p {
    font-size: 1vw;
    font-style: italic;
}


/* -----------------preview------------- */

#preview {
    display: grid;
    grid-template-columns: 18.75% 18.75% 18.75% 18.75% 25%;
    max-height: 400px;
    margin-top: 100px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

#preview img {
    object-fit: cover;
    width: 100%;
    height: 300px;
    pointer-events: none;
}

#preview h3 {
    opacity: 0.4;
}

#preData {
    padding-left: 5%;
    background-color: white;
    color: black;
    z-index: 3;
}

#preData button {
    background-color: white;
    border: 1px solid black;
    padding: 4px 7px;
}

#preData button:hover {
    border: 1px solid white;
    background-color: tomato;
    cursor: pointer;
}

@media only screen and (max-width: 1100px) {
    #intro {
        grid-template-columns: 100%;
    }
    #slideshow {
        display: none !important;
    }
    #slide1 h1 {
        margin-top: 45vh;
    }
    #about {
        padding-top: 50px;
    }
    #about_services {
        width: 100%;
    }
    #location {
        padding-bottom: 100px;
    }
    #review div p {
        font-size: 1.4vw;
    }
    #form {
        display: inline-block;
    }
    #form form {
        margin-top: 80px;
        max-width: 450px;
        margin: auto;
    }
}

@media only screen and (max-width: 900px) {
    .section {
        width: 90%;
        margin-left: 5%;
    }
    #about {
        grid-template-columns: 100%;
    }
    #about img {
        display: none;
    }
    #review div p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {}