#slideshow {
    margin-top: 10vh;
    height: 80vh;
    width: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    scrollbar-color: white white;
}

#show_display {
    display: flex;
    align-content: stretch;
    flex: 1;
    flex-wrap: wrap;
    margin: 1vmin;
    width: 99%;
    max-height: 400px;
}

#slide_item {
    padding-bottom: 20px;
    margin-left: 10px;
}

#slide_item img {
    width: 100%;
    border: 2px solid black;
}

#slide_item button {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 8px;
    margin-top: 10px;
    border: none;
}

#slide_item button:hover {
    cursor: pointer;
    background-color: tomato;
    color: white;
}

/* ------------------------------------------- */

.hvrbox, .hvrbox * {
    box-sizing: border-box;
}

.hvrbox {
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.hvrbox img {
    max-width: 100%;
}

.hvrbox .hvrbox-layer_bottom {
    display: block;
}

.hvrbox .hvrbox-layer_top {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    /* background: rgba(255, 73, 73, 0.6); */
    color: #fff;
    padding: 15px;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.hvrbox:hover .hvrbox-layer_top, .hvrbox.active .hvrbox-layer_top {
    opacity: 1;
}

.hvrbox .hvrbox-text {
    text-align: center;
    font-size: 18px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.hvrbox .hvrbox-text_mobile {
    font-size: 15px;
    border-top: 1px solid rgb(179, 179, 179);
    /* for old browsers */
    border-top: 1px solid rgba(179, 179, 179, 0.7);
    margin-top: 5px;
    padding-top: 2px;
    display: none;
}

.hvrbox.active .hvrbox-text_mobile {
    display: block;
}